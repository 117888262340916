.ps__rail-y {
	display: none !important;
}

.ps__rail-x {
	display: none !important;
}

body {
	font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif'
}